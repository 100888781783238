<template>
    <div class="index-content">
        <div class="guide">
            <p class="guide-title">考级指南</p>
            <!-- <p class="guide-title1">2020年寒假美术书法等级考试（深圳考区）</p> -->
            <p class="guide-title1" v-html="content"></p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'guide',
    data(){
        return{
            content:''//内容
        }
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/guide',
                method:'POST',
                data:{}
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                    this.content=res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .index-content{
        background: #F6F6EA;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .guide{
        width: 1200px;
        background: #ffffff;
        padding: 60px 40px;
    }
    .guide-title{
        font-weight: 600;
        color: #333333;
        font-size: 30px;
        padding-bottom: 40px;
        text-align: center;
    }
    .guide-title1{
        color: #333333;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
    }
    .guide-name{
        font-weight: bold;
        padding: 50px 0;
    }
</style>